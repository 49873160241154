import { monitorUrls } from './monitorService';

import { sharedenvironment } from './frontend-defaults';
import { frontendNamesTest, ServerConfigEnum } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://test.orgbrain.ai/';

const myenvironment = {
  production: true,
  name: 'TEST',
  defaultServerConfig: [ServerConfigEnum.TEST_SERVERS],
  logServiceUrl: monitorUrls.preprod.url, // DEPreCAte ME
  academyBucketName: 'orgbrain-beta-site',
  debug: true,
  monitorFrontendUrl: 'https://monitor.orgbrain.ai?target=devel',

  appBase: APP_BASE_URL,

  meetingEmailRecipientDomain: 'staging-meeting.orgbrain.ai',

  logoURL: 'https://orgbrain-beta-site.s3.eu-central-1.amazonaws.com/',

  enableLocalSW: false,

  frontendList: frontendNamesTest,

  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankid2FA: true,

  //paul-leonard-organization-test.criipto.id/oauth2/authorize?scope=openid&response_type=id_token&response_mode=fragment&nonce=ecnon-30e09b3d-6594-4a71-9694-e4ba5acc0a07&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fauth&client_id=urn%3Amy%3Aapplication%3Aidentifier%3A2386&acr_values=urn%3Agrn%3Aauthn%3Ano%3Abankid&criipto_sdk=dashboard
  oauth: {} as any,
  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://idp-develop-devdb.staging.cozone.com/oauth/login',
    jwksURL: 'https://idp-develop-devdb.staging.cozone.com/api/v1/oauth2/jwks',
  } as any,

  GET_RID_OF_ALL_THE_RUBISH_CRETED_BY_FRONTEND_ERRORS_THAT_MAKES_DEBUGING_IMPOSSIBLE: true,
  // Activity will nudge the asscessToken when wwe get this close to the sessionTime
  adobe_embedded_key: 'f28a06dc98744422a87d17b099e0beff',
  // WTF lets keep this off for local builds ?   But it keeps on getting turned on
  loguncaughtErrors: false,
  NO_SPLASH_TEST: true,
  debugChat: true,
};

export const environment = Object.assign(sharedenvironment, myenvironment);
